.createUrlForm{
    &__fields{
        display: flex;
        flex-direction: column;
        margin: 0 0 1.5rem;

        label{
            margin: 0 0 1rem;
        }

        input{
            margin: 0 0 1.5rem;
        }
    }

    &__buttons{
        display: flex;
        justify-content: flex-end;

        button{
            width: 120px;

            &:first-child{
                margin: 0 1rem 0 0;
            }
        }
    }
}

.CeateUrlBackground{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 60%;
    height: auto;
}
