@import "../general/general";

@mixin background($width, $heigth) {
  position: absolute;
  width: $width;
  height: $heigth;
  z-index: -1;
}

.createScoretypeBackground1 {
  @include background(auto, 55%);
  top: 0;
  right: 0;
}

.createScoretypeBackground2 {
  @include background(50%, auto);
  bottom: 0;
  left: 0;
}
