.jobstable {
  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    p {
      padding: 0 !important;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &__link {
      display: flex;
    }
    &__status {
      margin-right: 25px;
    }
    &__check {
      color: var(--accentGreen);
      font-size: 30px;
    }
    &__error {
      color: var(--accentRed);
      font-size: 30px;
    }
    cursor: pointer;
    &:hover {
      background-color: var(--primaryColor);
      svg {
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
    p {
      padding: 0 20px 0 0;
    }
  }
}
