// // COLORS
// $backgroundColor: #fbfaf9;
// $primaryColor: #0a006f;
// $accentGreen: #7ee1b8;
// $accentBlue: #6c5dd3;
// $accentRed: #e776a2;
// $accentOrange: #ffb549;
// $inputNa: #a9aab6;
// $inputA: #6c5dd3;
// $buttonHoverPrimary: #5e51b1;
// $buttonHoverSecondary: #c7668b;
// $buttonHoverCancel: #5e51b1;

// // FONTS
// $headerFontSize: 2rem;
// $bodyFontSize: 1rem;
// $bodyFont: muli, sans-serif;

// MEDIAQUERRY SIZES
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1201px;

:root {
  // COLORS
  --backgroundColor: #fbfaf9;
  --primaryColor: #0a006f;
  --accentGreen: #7ee1b8;
  --accentBlue: #6c5dd3;
  --accentRed: #e776a2;
  --accentOrange: #ffb549;
  --inputNa: #a9aab6;
  --inputA: #6c5dd3;
  --buttonHoverPrimary: #5e51b1;
  --buttonHoverSecondary: #c7668b;
  --buttonHoverCancel: #5e51b1;
  --buttonHoverPlay: #0bc678;

  // FONTS
  --headerFontSize: 2rem;
  --bodyFontSize: 1rem;
  --bodyFont: muli, sans-serif;

  //   // MEDIAQUERRY SIZES
  //   --sm: 576px;
  //   --md: 768px;
  //   --lg: 992px;
  //   --xl: 1201px;
}
