.toggle {
  background-color: #fff;
  border-radius: 5px;
  &__input {
    display: none;
  }
  &__header {
    border: 3px solid var(--accentBlue);
    border-radius: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    color: #0a006f;
    background-color: #fff;
    border-color: #fff;
    cursor: pointer;
    min-width: 60px;
    z-index: 10;
    &.active {
      cursor: default;
      background-color: var(--accentBlue);
      color: #fff;
      border-color: var(--accentBlue);
    }
  }
}

