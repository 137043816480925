@import "../general/general.scss";

@mixin focusInput {
  border: 1px solid var(--inputA);
  box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
  outline: none;
}

@mixin defaultInputStyle($width) {
  width: $width;
}

.input {
  border-radius: 5px;
  border: 1px solid var(--inputNa);
  height: 40px;
  background-color: #fff;
  padding: 0 0.5rem;
  width: 100%;

  &:focus {
    @include focusInput;
  }
}
