.jobdetail-top {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 150px);
  grid-column-gap: 25px;
  grid-row-gap: 25px;

  & > * {
    cursor: default;

    &:not(.jobdetail-top__logs) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h4 {
    font-size: 1.5rem;
  }

  &__pass {
    grid-area: 1 / 1 / 2 / 2;
    h4 {
      color: #fff;
    }

    &--green {
      background-color: var(--accentGreen);
    }
    &--red {
      background-color: var(--accentRed);
    }
  }

  &__info {
    grid-area: 2 / 1 / 3 / 2;
  }

  &__seconds {
    grid-area: 3 / 1 / 4 / 2;
  }

  &__logs {
    h4 {
      padding-bottom: 2rem;
    }

    h5 {
      font-size: 1.2rem;
      padding: 0.5rem;
    }

    grid-area: 1 / 2 / 4 / 5;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
  }
}

.jobdetail-bottom {
  h3 {
    padding: 2rem 0;
  }
  &__screenshots {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    justify-items: center;

    & > * {
      height: auto;
      width: 100%;
    }
  }
}
