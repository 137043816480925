@import "../general/general";

@mixin background {
  position: absolute;
  height: 100%;
  width: auto;
  z-index: -1;
}

.projectform {
  &__logoAndPreview {
    display: flex;
    justify-content: space-between;

    & > section {
      width: calc(50% - 10px);
      display: flex;
      justify-content: space-between;
      margin: 0 0 1rem;

      section {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;

        button {
          width: 100%;
        }

        .input-logo > input {
          display: none;
        }

        img {
          height: 40px;
          object-fit: contain;
        }

        label:not(.input-logo) {
          margin: 0 0 1rem;
        }
      }
    }
  }
}

.projectBackground1 {
  top: 0;
  left: 0;
  @include background();
}

.projectBackground2 {
  bottom: 0;
  right: 0;
  @include background();
}
