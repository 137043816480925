@import "./general.scss";

// * style
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

h1,
h2 {
  color: var(--primaryColor);
}

h3,
h4,
h5 {
  color: var(--primaryColor);
  font-family: var(--bodyFont);
  font-weight: bold;
}

p {
  color: var(--primaryColor);
  font-family: var(--bodyFont);
  font-size: var(--bodyFontSize);
}
li {
  color: var(--primaryColor);
  font-family: var(--bodyFont);
  font-size: var(--bodyFontSize);
}

label {
  color: var(--primaryColor);
  font-family: var(--bodyFont);
  font-size: var(--bodyFontSize);
}

body {
  background-color: var(--backgroundColor);
  #root {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
  }
}

main {
  @media only screen and(max-width: $sm) {
    padding: 20px;
  }
  padding: 0 50px 50px 50px;
  max-width: 1600px;
  margin: 0 auto;
}
