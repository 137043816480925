@mixin circleColor($color, $left) {
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $color;
    top: 8px;
    left: $left;
  }
}

.legend {
  display: flex;
  justify-content: space-between;
  ul {
    display: flex;
    position: relative;
    align-items: center;
    li {
      list-style: none;
      margin-left: 35px;
    }
    .listgreen {
      @include circleColor(var(--accentGreen), 15px);
    }
    .listorange {
      @include circleColor(var(--accentOrange), 90px);
    }
    .listred {
      @include circleColor(var(--accentRed), 185px);
    }
  }
}
