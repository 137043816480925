@import "../general/general.scss";

@mixin inputfield {
  height: 2.5rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--inputNa);
}

.container {
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  @media only screen and(max-width: $sm) {
    flex-direction: column;
    padding: 15% 0 0 0;
  }
  .form {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    padding: 0 5% 10% 5%;
    z-index: 10;
    @media only screen and(max-width: $sm) {
      padding: 10% 5%;
      display: block;
      order: 2;
    }
    &__titleBox {
      width: 100%;
    }
    &__title {
    }
    &__container {
      padding: 3rem 0 0 0;
      width: 100%;
    }
    &__box {
      padding: 2.5rem 0 0 0;
      display: flex;
      flex-direction: column;
    }
    &__label {
      padding: 0 0 0.2rem 0;
    }
    &__input {
      @include inputfield;
      &:focus {
        border: 1px solid var(--inputA);
        box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
        outline: none;
      }
    }
    &__btn {
      width: 100%;
      margin: 2rem 0 0 0;
    }
  }
  .box {
    flex: 2;
    background-color: var(--primaryColor);
    display: flex;
    justify-content: center;
    @media only screen and(max-width: $sm) {
      display: none;
    }
    &__name {
      transform: scale(1.5);
      width: 25vw;
      height: 50vh;
    }
  }
  &__image {
    position: absolute;
    bottom: 5rem;
    transform: scale(1.2);
    width: 90vw;
    height: auto;
    @media only screen and(max-width: $sm) {
      transform: rotate(20deg);
      width: 200vw;
      height: auto;
      left: -400px;
      bottom: 25px;
    }
  }
  &__mobile_login {
    display: none;
    @media only screen and(max-width: $sm) {
      display: block;
      position: absolute;
      bottom: 0;
      z-index: -1;
      width: 100vw;
      height: auto;
    }
  }
  &__mobile_name {
    display: none;
    @media only screen and(max-width: $sm) {
      display: block;
      width: 100%;
      order: 1;
    }
  }
}
