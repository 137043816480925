@import "../general/general.scss";

.dashboard {
  min-height: calc(100vh - 80px);
  overflow: hidden;
  &-upper {
    margin-top: 25px;
    .performance {
      @media only screen and(max-width: $sm) {
        margin-top: 45px;
      }
      &__card {
        @media only screen and(min-width: $md) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 25px;
        }
      }
      &__header {
        display: flex;
        justify-content: space-between;
      }
      &__content {
        margin-top: 15px;
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        height: 400px;
        &__stats {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: var(--primaryColor);
          padding: 10px 15px;
          border-radius: 5px 5px 0 0;
          max-height: 50px;
          .statsscores {
            display: flex;
            gap: 15px;
            &__mobiledesktop {
              display: flex;
              svg {
                padding: 0;
              }
              p {
                align-self: center;
                padding: 6px 0 0 0;
              }
              &:last-child {
                margin: 0 0 0 15px;
                p {
                  margin: 0 0 0 10px;
                }
              }
            }
          }
        }
      }
      &__btn {
        border: 2px var(--accentBlue) solid;
        border-radius: 5px;
        background-color: #fff;
        padding: 5px;
      }
      &__pages {
        margin-top: 15px;
        &__content {
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__box {
        height: 100%;
        max-height: 100%;
        max-height: 350px;
      }
      &__box__sites {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      &__sites {
        margin: 17px;
        max-width: 280px;
      }
      &__box__container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &-lower {
    margin-top: 25px;
    .project {
      &__header {
        margin-top: 25px;
        margin-bottom: 15px;
        text-align: center;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            max-height: 100%;
            width: auto;
            max-width: 100%;
          }
          img {
            max-height: 100%;
            width: 100%;
            max-width: 100%;
          }
        }
      }

      &__content {
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        height: 320px;
        margin-bottom: 50px;
        &__stats {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: var(--primaryColor);
          padding: 10px 15px;
          border-radius: 5px 5px 0 0;
          .statsscores {
            display: flex;
            &__mobiledesktop {
              display: flex;
              svg {
                padding: 0;
              }
              p {
                align-self: center;
                padding: 6px 0 0 4px;
              }
              &:last-child {
                margin: 0 0 0 15px;
                p {
                  margin: 0 0 0 4px;
                }
              }
            }
          }
        }
      }
      &__btn {
        border: 2px var(--accentBlue) solid;
        border-radius: 5px;
        background-color: #fff;
        padding: 5px;
      }
      &__pages {
        margin-top: 15px;
        &__content {
          margin: 20px 0 0 0;
          height: 265px;
        }
      }
      &__box__sites {
        display: flex;
        flex-direction: column;
      }
      &__sites {
        margin: 20px;
        max-width: 280px;
      }
      &__box__container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.gauge {
  width: 75%;
  max-width: 250px;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  &__body {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    // background: #b4c0be;
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden;
  }
  &__fill {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: 100%;
    background: #009578;
    transform-origin: center top;
    transform: rotate(0.25turn);
    transition: transform 1s ease-out;
  }
  &__cover {
    width: 75%;
    height: 150%;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);

    /* Text */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
    color: #0a006f;
  }
  &__text {
    text-align: center;
  }
}

.charts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}

.splide__arrow svg {
  fill: #0a006f !important;
  &:hover {
    fill: var(--accentBlue) !important;
  }
}

.svg {
  margin-left: -50px;
  margin-right: -50px;
  margin-top: 40px;
}

.splide__pagination__page.is-active {
  background: #0a006f !important;
}

.splide__pagination__page:hover {
  background: var(--accentBlue) !important;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
}

.dashboard_background {
  position: absolute;
  bottom: 0;
  z-index: -10;
  height: 100%;
  width: auto;
}
.projectcard_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}
