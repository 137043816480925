@import "../general/general.scss";

.editScoretypeBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: auto;
  z-index: -1;
}
