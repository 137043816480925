@import "../general/general.scss";

.dashboard2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(auto-fill, 200px);
  grid-gap: 20px;
  justify-content: center;
  position: relative;
  height: calc(100vh - 69px);
  padding-top: 1rem;
  @media only screen and(max-width: $sm) {
    padding-top: 3rem;
    grid-template-columns: repeat(auto-fill, 47%);
    column-gap: 6%;
    justify-content: flex-start;
  }
  .card {
    .statusLine {
      position: absolute;
      top: 0;
      height: 6px;
      width: 100%;
      border-radius: 5px 5px 0 0;
      &--red {
        background-color: var(--accentRed);
      }
      &--orange {
        background-color: var(--accentOrange);
      }
      &--green {
        background-color: var(--accentGreen);
      }
    }

    &__logocontainer{

    }

    &__logo {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      svg {
        height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
  }
  &__bg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    min-width: 100vw;
    height: auto;
    @media only screen and(max-width: $sm) {
      opacity: 65%;
    }
  }
}
