.btn {
  font-size: var(--bodyFontSize);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;

  &--primary {
    background-color: var(--accentBlue);

    &:hover {
      background-color: var(--buttonHoverPrimary);
    }
  }

  &--secondary {
    background-color: var(--accentRed);

    &:hover {
      background-color: var(--buttonHoverSecondary);
    }
  }
&--play {
    background-color: var(--accentGreen);

    &:hover {
      background-color: var(--buttonHoverPlay);
    }
  }

  &--cancel {
    border: 2px solid var(--accentBlue);
    background-color: #fff;
    color: var(--accentBlue);

    &:hover {
      border: 2px solid var(--buttonHoverPrimary);
      color: #fff;
      background-color: var(--buttonHoverPrimary);
    }
  }

  &--img {
    width: 20px;
    height: auto;
  }
}
