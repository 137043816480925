@import "../general/general.scss";

.editProject {
  .uploadFile {
    width: calc(50% - 10px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    & > section {
      width: calc(50% - 10px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        height: 40px;
        object-fit: contain;
      }
    }

    .input-logo > input {
      display: none;
    }

    button {
      margin: 14px 0 0 0;
    }
  }

  &__header {
    max-width: 800px;
    margin: 0 auto;
    padding: 3rem 0;
    &__logo{
      max-height: 150px;
    }
  }
}
