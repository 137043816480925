@import "../general/general.scss";

@mixin inputfield {
  height: 2.5rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--inputNa);
}

.row {
  &__title {
    padding: 3rem 0 2rem 0;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 20px;
    justify-items: center;
    @media only screen and(max-width: $sm) {
      grid-template-columns: repeat(auto-fill, 47%);
      column-gap: 6%;
    }
    .card {
      width: 100%;
      min-height: 250px;
      padding: 2.5rem 0;
      @media only screen and(max-width: $sm) {
        min-height: 140px;
        padding: 1rem 0;
      }
      &__img {
        img {
          @media only screen and(max-width: $sm) {
            width: 50px;
          }
        }
      }
      &__tag {
        position: absolute;
        bottom: 50px;
        @media only screen and(max-width: $sm) {
          bottom: 20px;
        }
      }
    }
    .menucard {
      overflow: hidden;

      &:hover > div.menucard__inactive {
        right: -20px;
      }

      &__inactive {
        transition: right 0.2s ease;
        will-change: right;
        position: absolute;
        transform: skew(6deg);
        width: 40px;
        height: 100%;
        background-color: var(--accentGreen);
        top: 0;
        right: -60px;
      }
    }
  }
}
.menu_background {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  min-height: 100vh;
  width: auto;
  z-index: -1;
  @media only screen and(max-width: $sm) {
    display: none;
  }
}
.menu_mobile_background {
  display: none;
  @media only screen and(max-width: $sm) {
    display: block;
    position: absolute;
    top: 50px;
    width: 100vw;
    height: auto;
    z-index: -1;
  }
}
