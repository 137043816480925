@import "../general/general.scss";

.projects {
  position: relative;
  &__add {
    width: 100px;
    margin: 2rem 0;
    z-index: 1;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 252px);
    grid-template-rows: repeat(auto-fill, 250px);
    grid-gap: 20px;
    justify-content: flex-start;
    @media only screen and(max-width: $sm) {
      grid-template-columns: repeat(auto-fill, 47%);
      column-gap: 6%;
      justify-content: flex-start;
    }
    .carditem {
      z-index: 9;
      overflow: hidden;
      position: relative;
      width: 100%;

      &__btn {
        margin: 1rem 0 0 0;
        width: 100%;
      }
      &__dev {
        position: absolute;
        z-index: -10;
        bottom: -50px;
        left: -45px;
        width: 55%;
        height: auto;
      }
      &__logo {
        height: 100%;
        max-height: 55px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
  &__bg1 {
    position: absolute;
    bottom: -450px;
    min-width: 100vw;
    height: auto;
    z-index: -1;
  }
  &__bg2 {
    position: absolute;
    bottom: -450px;
    width: 100vw;
    height: auto;
    z-index: -1;
  }
}
