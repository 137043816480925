@import "../general/general.scss";

.projecttop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "error error"
    "scores buttons";
  row-gap: 20px;
  &__scores {
    grid-area: scores;
    display: flex;
    &__mobile {
      margin: 0 10px 0 0;
      padding: 0 4px;
      display: flex;
      p {
        align-self: center;
      }
      &--active {
        border-bottom: 2px solid var(--primaryColor);
      }
    }
    &__desktop {
      display: flex;
      p {
        padding: 0 0 0 5px;
        align-self: center;
      }
      &--active {
        border-bottom: 2px solid var(--primaryColor);
      }
    }
  }
  &__error {
    grid-area: error;
    display: flex;
    &__svg {
      color: var(--accentRed);
      font-size: 1.5rem;
      margin: 0 5px 0 0;
    }
    &__text {
      align-self: center;
      color: var(--accentRed);
    }
  }
  &__buttons {
    grid-area: buttons;
    display: flex;
    justify-self: end;
    &__btn {
      width: 70px;
      @media only screen and(min-width: $sm) {
        width: 100px;
      }
    }
    &__edit {
      margin: 0 20px 0 0;
    }
  }
}

.projectinfo {
  @media only screen and(min-width: $sm) {
    display: flex;
  }
  &__performance {
    @media only screen and(min-width: $sm) {
      width: 600px;
      min-width: 400px;
      margin: 40px 20px 0 0;
    }
    margin: 40px 0 0 0;
    &__container {
      margin: 10px 0 0 0;
      background-color: #fff;
      border-radius: 5px;
      height: 290px;
      width: 100%;
    }
  }
  &__pages {
    @media only screen and(min-width: $sm) {
      width: 890px;
      min-width: 840px;
    }
    margin: 40px 0 0 0;
    &__container {
      width: 100%;
      background-color: #fff;
      border-radius: 5px;
      margin: 10px 0 0 0;
      height: 378px;
      @media only screen and(max-width: $sm) {
        height: 200px;
      }
    }
  }
}

.projectmetrics {
  margin: 40px 0 0 0;
  &__boxcontainer {
    margin: 10px 0 0 0;
    display: flex;
    overflow-x: scroll;
    @media only screen and(min-width: $sm) {
      justify-content: space-between;
      overflow-x: visible;
    }
  }
  &__box {
    position: relative;
    overflow: hidden;
    min-width: 206.5px;
    height: 88px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-child {
      margin: 0;
    }
    h3,
    p {
      z-index: 1;
    }
    &__icon {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.2rem;
      color: var(--primaryColor);
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__corner {
      position: absolute;
      width: 100px;
      height: 50px;
      background-color: var(--accentGreen);
      transform: rotate(-45deg);
      top: -15px;
      left: -35px;
    }
  }
}

.projectperformancegrid {
  display: flex;
  justify-content: space-between;
}

.projectperformance {
  margin: 40px 0 0 0;
  display: flex;
  &__boxcontainer {
    margin: 10px 0 0 0;
    display: flex;
    overflow-x: scroll;
    @media only screen and(min-width: $sm) {
      justify-content: space-between;
      overflow-x: visible;
    }
  }
  &__box {
    transition: box-shadow 0.3s;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 88px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    &:last-child {
      margin: 0;
    }
    h3,
    p {
      z-index: 1;
    }
    &__icon {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.2rem;
      color: var(--primaryColor);
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__corner {
      position: absolute;
      width: 100px;
      height: 50px;
      background-color: var(--accentGreen);
      transform: rotate(-45deg);
      top: -15px;
      left: -35px;
    }
  }
}

.projectsitemap {
  &__headings {
    display: flex;
    justify-content: space-between;
    &__urls {
      display: flex;
      flex: 2;
      margin-top: 5px;
      color: var(--primaryColor);
      font-weight: bold;
      font-size: 15px;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__mob {
      display: flex;
      flex: 1;
      margin-top: 5px;
      color: var(--primaryColor);
      font-weight: bold;
      font-size: 15px;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__desk {
      display: flex;
      flex: 1;
      margin-top: 5px;
      color: var(--primaryColor);
      font-weight: bold;
      font-size: 15px;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__error {
      display: flex;
      flex: 1;
      margin-top: 5px;
      color: var(--primaryColor);
      font-weight: bold;
      font-size: 15px;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__empty {
      display: flex;
      flex: 1;
    }
  }
  margin: 40px 0 0 0;
  &__boxcontainer {
    margin: 10px 0 0 0;
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;

    &__link {
      display: flex;
      flex: 2;
    }
    &__scoremob {
      display: flex;
      flex: 1;
      align-items: center;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__scoredesk {
      display: flex;
      flex: 1;
      align-items: center;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }

      p {
        margin-left: 5px;
      }
    }
    &__error {
      display: flex;
      flex: 1;
      color: var(--accentRed);
      font-weight: bold;
      font-size: 25px;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__check {
      display: flex;
      flex: 1;
      color: var(--accentGreen);
      font-weight: bold;
      font-size: 25px;
      place-content: center;
      @media only screen and(max-width: $sm) {
        display: none;
      }
    }
    &__error__mob {
      margin-top: 5px;
      color: var(--accentRed);
      font-weight: bold;
      font-size: 25px;
      place-content: center;
      @media only screen and(min-width: $sm) {
        display: none;
      }
    }
    &__button {
      display: flex;
      flex: 1;
      place-content: flex-end;
      @media only screen and(max-width: $sm) {
        display: none;
      }
      button {
        width: 50%;
      }
    }
    cursor: pointer;
    &:hover {
      background-color: var(--primaryColor);
      svg {
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
    p {
      padding: 0 20px 0 0;
    }
    &__icon {
      min-width: 32px;
      height: 32px;
      color: var(--primaryColor);
      @media only screen and(min-width: $md) {
        display: none;
      }
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    @media only screen and(min-width: $sm) {
      display: none;
    }
    .projectlink {
      display: flex;
      &__scores__mobile {
        display: flex;
        flex: 1;
        align-items: center;
        margin: 0 10px 0 0;
      }
      &__scores__desktop {
        display: flex;
        flex: 1;
        align-items: center;
        margin: 0 10px 0 0;
      }
      &__error__svg {
        display: flex;
        flex: 2;
        justify-content: flex-end;
        color: var(--accentRed);
        font-size: 2.5rem;
        margin: 0 10px 0 0;
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      div {
        width: 100%;
        padding: 10px;
        .projectlink__button {
          width: 100%;
        }
      }
    }
  }
}

.detail_background {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
}

.detail_background2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -10;
}

.bar {
  margin: 10px;
}

.pagination {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  &__center {
    display: flex;
    align-items: center;
  }
  &__pagebutton {
    background-color: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
    margin: 5px;
    margin-left: 15px;
    margin-right: 15px;
  }
  &__pageicon {
    font-size: 30px;
    color: var(--primaryColor);
  }
  &__count {
    font-size: 20px;
    color: var(--primaryColor);
  }
}
