@import "../general/general.scss";

.navigationminimal {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "logo title hamburger";
  row-gap: 50px;
  padding: 20px;
  @media only screen and(max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "logo hamburger"
      "title .";
    row-gap: 30px;
    padding: 20px 20px 0 20px;
  }
  &__logo {
    grid-area: logo;

    &__svg {
      height: auto;
      width: 150px;
      color: var(--primaryColor);
    }
  }
  &__title {
    grid-area: title;
    justify-self: center;
    @media only screen and(max-width: $sm) {
      justify-self: start;
    }
  }
  &__hamburger {
    font-size: 2rem;
    grid-area: hamburger;
    justify-self: end;
    @media only screen and(max-width: $sm) {
      justify-self: end;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
