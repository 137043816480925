.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 50%;
}

.quarterWidth {
  width: 25%;
}
