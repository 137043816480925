@import "../general/general";

.navigation {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "logo . . . hamburger"
    "back title title title .";
  row-gap: 50px;
  padding: 20px;
  @media only screen and(max-width: $sm) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "back . . hamburger"
      "title . . .";
    row-gap: 30px;
    padding: 20px 20px 0 20px;
  }
  &__logo {
    grid-area: logo;
    @media only screen and(max-width: $sm) {
      display: none;
    }
  }
  &__hamburger {
    font-size: 2rem;
    grid-area: hamburger;
    justify-self: end;
    &__img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__back {
    grid-area: back;
    padding: 0 0 0 30px;
    &__img {
      color: var(--primaryColor);
      font-size: 2rem;
      &:hover {
        cursor: pointer;
      }
    }
    @media only screen and(max-width: $sm) {
      padding: 0;
    }
  }
  &__title {
    grid-area: title;
    justify-self: center;
    &__img{
width: 50PX;
    }
  }
  @media only screen and(max-width: $sm) {
    justify-self: start;
  }
}
