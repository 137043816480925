@import "../general/general.scss";

.createuser {
  &__fields {
    @media screen and(max-width: $sm) {
      flex-direction: column;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 1.5rem;

    & > section {
      display: flex;
      flex-direction: column;

      label {
        margin: 0 0 1rem;
      }

      input {
        margin: 0 0 1.5rem;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    button {
      width: 120px;

      &:first-child {
        margin: 0 1rem 0 0;
      }
    }
  }
}

.createUserUpper {
  @media screen and(max-width: $sm) {
    width: 100%;
    height: auto;
  }

  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.createUserLower {
  @media screen and(max-width: $sm) {
    width: 100%;
    height: auto;
  }

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
