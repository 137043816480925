@mixin colorVariants {
  &--red {
    color: var(--accentRed);
  }
  &--orange {
    color: var(--accentOrange);
  }
  &--green {
    color: var(--accentGreen);
  }
}

.card {
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
  outline: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  &__logocontainer {
    min-height: 55px;
    display: flex;
    align-items: center;
  }
  &__logo {
    z-index: 10;
    max-width: 100%;
  }
  &__scores {
    z-index: 10;
    padding: 1rem 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  &__box {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__desktop {
    @include colorVariants;
    z-index: 10;
    width: 120%;
    height: 120%;
    padding-bottom: 0.5rem;
    font-size: 1.7rem;
  }
  &__mobile {
    @include colorVariants;
    z-index: 10;
    width: 120%;
    height: 120%;
    padding-bottom: 0.5rem;
    font-size: 1.7rem;
  }
  &__links {
    @include colorVariants;
    z-index: 10;
    width: 15%;
    height: 15%;
    padding-bottom: 0.5rem;
    font-size: 1.7rem;
  }
  &__text {
    @include colorVariants;
    z-index: 10;
    font-size: 1.2rem;
    font-weight: bold;
  }
}
