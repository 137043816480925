.form {
  max-width: 800px;
  margin: 0 auto;

  h2 {
    margin: 0 0 2rem;
  }

  &__fields {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 0 1.5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 1rem;

    label {
      margin: 0 0 1rem;
    }

    &--half {
      width: calc(50% - 10px);
    }

    &--emptyLeft {
      width: 100%;

      input,
      label {
        width: calc(50% - 10px);
        align-self: flex-end;
      }
    }

    &--emptyRight {
      width: 100%;

      input {
        width: calc(50% - 10px);
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 2rem;

    span {
      & > button {
        width: 120px;
      }

      &:last-child {
        margin: 0 0 0 1rem;
      }
    }

    span.delete {
      flex: 5;
    }
  }
}
